// @flow
import React from 'react';
import {
  GlossaryLink as G,
  GlossaryQuote,
  Layout,
  Link,
  withFrontMatter,
} from '../components';
import type { FrontMatter } from '../utils/types';
import {
  PagePreviewCard,
  PagePreviewCardDivider,
} from '../components/page-preview-card';
import frontMatterMap from '../data/front-matter';
import routes from '../routes';

type Props = {
  frontMatter: FrontMatter,
  data: {},
};

class Canyoneering_Anchors extends React.Component<Props> {
  render() {
    const { frontMatter } = this.props;

    const previews = frontMatter.children.map((id) => {
      const child = frontMatterMap[id];
      return <PagePreviewCard key={child.id} frontMatter={child} />;
    });

    return (
      <Layout frontMatter={frontMatter}>
        <h2>The beginning</h2>
        <p>
          When descending canyons we often get to a drop that it too difficult
          to <G>down climb</G> but we need to get down. How are we going to do
          it? Ladders are too bulky and heavy and don't reach very far. Rope
          ladders or <G>etriers</G> might work better but you'll need something
          to <G>anchor</G> it to at the top. And if it's a long distance it
          might be scary and I'd worry that someone would panic and accidentally
          let go.
        </p>
        <p>
          How about we use rope and then each person <G>rappel</G>? Rope is
          light-weight, the person can easily <G id="hard-lock-off">lock off</G>{' '}
          mid-rappel if the need to. They can easily be{' '}
          <G id="belay">belayed</G> from the <G id="top-rope-belay">top</G> or{' '}
          <G id="bottom-belay">bottom</G>. These advantages are why so many
          canyons require rappelling to safely descend.
        </p>
        <p>
          We still need to solve the anchor problem, though. We need something
          solid at the top to hold the rope and anything loading it. It should
          be <G>redundant</G> because we might not necessarily be able to trust
          it. It should be able to survive weather, heat, sunlight, and freezing
          temperatures. It shouldn't cost too much money if we have to purchase
          material that we leave behind. Leaving it should not be illegal. It
          shouldn't be ugly or detract from the natural setting, either.
        </p>
        <h2>Topics</h2>
        <PagePreviewCardDivider />
        {previews}
        <h2>Topics I want to expand upon eventually</h2>
        <h3>Solid anchors</h3>
        <ul>
          <li>
            <span style={{ textDecoration: 'line-through' }}>
              Single solid{' '}
              <Link to={routes.page_single_solid_natural_anchors}>
                natural anchors
              </Link>{' '}
            </span>{' '}
            (done)
          </li>
          <li>
            <G>bolts</G>
          </li>
        </ul>
        <h3>Webbing</h3>
        <GlossaryQuote>webbing</GlossaryQuote>
        <p>Webbing is tied around anchors and left behind.</p>
        <h3>Webbing knots</h3>
        <ul>
          <li>
            <G>Ring bend</G>
          </li>
          <li>
            <G>Frost knot</G>
          </li>
          <li>Frost knot variations</li>
          <li>
            <G>Master point</G>
          </li>
        </ul>
        <h3>Evaluating common anchors</h3>
        <ul>
          <li>Two bolt anchor with master point</li>
          <li>Single loop with a ring bend</li>
          <li>Single loop with a mostly-pointless master point</li>
          <li>Single loop with frost knot</li>
          <li>
            <G>Basket hitch</G> with master point
          </li>
          <li>
            <G>Wrap 3 pull 2</G>
          </li>
          <li>
            <G>Girth hitch</G> (and choke hitch)
          </li>
        </ul>
        <h3>Creating multi-point anchors</h3>
        <p>
          These are anchors with multiple pieces of webbing. If I ever get to
          this I will go through how to make them all meet up at a master point.
        </p>
        <h3>Marginal anchors</h3>
        <GlossaryQuote>marginal-anchor</GlossaryQuote>
        <GlossaryQuote>transient-anchor</GlossaryQuote>
        <p>
          Much bigger topic and definitely intermediate/advanced (everything on
          this page so far is beginner).
        </p>
        <p>
          Topics would be <G>cairn</G>, <G id="deadman-anchor">deadman</G>, and{' '}
          <G id="meat-anchor">meat</G> anchors. Could eventually get to{' '}
          <G>transient anchors</G>.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('anchors')(Canyoneering_Anchors);
